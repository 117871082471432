<template>
<!--  物流信息填写-->
  <div class="main-container">
    <el-breadcrumb separator="/" class="breadcrumb" style="margin:0.6rem;display: inline-block;">
      <el-breadcrumb-item style="">资产管理</el-breadcrumb-item>
      <el-breadcrumb-item>{{$route.query.page_type=='wl' ? '发货' : $route.query.page_type=='wl-check' ? '物流信息查看' : '出库详情'}}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-link size="mini" type="primary"  icon="el-icon-back" class="back-btn" @click="goBack">返回上一页</el-link>
    <div class="content-block">
      <am-detail :isBreadcrumb="false" :isMapShow="false" @getForm="getFormData"></am-detail>
      <div class="content-title  separate-line">
        <p>出库信息</p>
      </div>
      <div>
        <el-descriptions title="" :column="3" :colon="false">
          <el-descriptions-item label="出库单号：">{{form.checkout_no}}</el-descriptions-item>
          <el-descriptions-item label="出库时间：">{{form.checkout_time}}</el-descriptions-item>
          <el-descriptions-item label="出库操作人：" span="2">{{form.checkout_user_name}}</el-descriptions-item>
          <el-descriptions-item label="终端用户：">{{form.checkout_obj_name}}</el-descriptions-item>
          <el-descriptions-item label="用户名称：" span="2">{{form.obj_name}}</el-descriptions-item>
          <el-descriptions-item label="联系人：">{{form.contact_info ? form.contact_info.contact_user : ''}}</el-descriptions-item>
          <el-descriptions-item label="联系电话：">{{form.contact_info ?form.contact_info.contact_phone : ''}}</el-descriptions-item>
        </el-descriptions>
      </div>
<!--      <div class="content-title  separate-line">-->
<!--        <p>经销商信息</p>-->
<!--      </div>-->
<!--      <div>-->
<!--        <el-descriptions title="" :column="1" :colon="false">-->
<!--          <el-descriptions-item label="名称：">{{form.customer_name}}</el-descriptions-item>-->
<!--          <el-descriptions-item label="地址：">{{form.hos_pos}}</el-descriptions-item>-->
<!--          &lt;!&ndash;          <el-descriptions-item label="联系人" span="2">{{form.engineer_name}}</el-descriptions-item>&ndash;&gt;-->
<!--          &lt;!&ndash;          <el-descriptions-item label="联系电话">{{form.link}}</el-descriptions-item>&ndash;&gt;-->
<!--        </el-descriptions>-->
<!--      </div>-->
      <logistics-check v-if="['wl','wl-check'].includes(page_type)" :instrument_status="form.instrument_status"
                       :express_no="form.express_no" :express_com="form.express_com" selfType="ziti"></logistics-check>
    </div>

  </div>

</template>
<script>

import AssetsInfo from "@/views/AssetManagement/components/assetsInfo.vue";
import AmDetail from "@/views/AssetManagement/amDetail.vue";
import logisticsCheck from "@/views/AssetManagement/components/logisticsCheck.vue";
import {getCheckoutInfo} from "@/api/assetMgt";
import TJDetailTitle from "@/components/TJDetailTitle.vue";

export default {
  name: 'outStoreDtl', // 生成出库单/ 物流发货
  components: {TJDetailTitle, AssetsInfo,logisticsCheck,AmDetail},
  data(){
    return{
      page_type: this.$route.query.page_type,
      options: [],
      form:{
        hospital: '',
        time: '',
        name: '',
        sale: '',
        number: '',

      },
    }
  },
  created() {},
  methods:{
    getFormData(val){
      this.form = Object.assign({},val,this.form)
    },
    goBack(){
      this.$router.push('/assetMgt/list')
    },
    handleRemove(file, fileList) {
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/css/asset';
.footer{
  text-align: center;
}
//特殊详情处理
.zone1{
  padding: 0;
}
::v-deep .el-tabs__header{
  padding-left: 10px;
}
</style>