<template>
  <div>
    <el-descriptions :title="isTitleShow ? '基础信息' : ''"  :column="4">
      <template slot="title">
        <img class="descriptions-icon" src="@/assets/img/titleIcon.png">
        <h4 class="descriptions-title">{{ isTitleShow ? '基础信息' : '' }}</h4>
      </template>
      <el-descriptions-item label="仪器序列号">{{form.instrument_id}}</el-descriptions-item>
      <el-descriptions-item label="仪器型号">{{form.platform_unique}}</el-descriptions-item>
      <el-descriptions-item label="状态" span="2">{{form.asset_status_name}}</el-descriptions-item>
      <el-descriptions-item label="入库单号">{{form.odd_no}}</el-descriptions-item>
      <el-descriptions-item label="入库时间">{{form.odd_time}}</el-descriptions-item>
      <el-descriptions-item label="入库操作人" span="2">{{form.odd_user}}</el-descriptions-item>
      <el-descriptions-item label="上位机版本">{{form.version_upper}}</el-descriptions-item>
      <el-descriptions-item label="下位机版本">{{form.version_lower}}</el-descriptions-item>
      <el-descriptions-item label="进样架版本">{{form.version_sample}}</el-descriptions-item>
      <el-descriptions-item label="CPRS版本">{{form.version_gprs}}</el-descriptions-item>
    </el-descriptions>
  </div>
</template>
<script>
import {getAssetDtl} from "@/api/assetMgt";

export default {
  props:{
    isTitleShow:{
      type:Boolean,
      default: true,
    }
  },
  data(){
    return{
      form:{
        instrument_id: '',
        platform_unique: '',
        asset_status_name: '',
        odd_no: '',
        odd_time: '',
        odd_user: '',
        version_upper: '',
        version_lower: '',
        version_sample: '',
        version_gprs: '',
      }
    }
  },
  created() {
    this.getDtl()
  },
  methods:{
    getDtl(){
      getAssetDtl({instrument_id: this.$route.query.instrument_id}).then(res => {
        this.form = res.data
        this.$emit('getForm', res.data)
      })
    }
  }
}

</script>

<style scoped lang="scss">

</style>